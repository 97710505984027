/*==============================================
  Base Styles
==============================================*/
@import './helper.scss';

* {
	box-sizing: border-box;
}

body {
	width: 100vw;
	background-color: #1d1d1d;

	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: #FFFFFF;
	font-size: 16px;
	margin: 0;
	width: 100vw;
	overflow-x: hidden;

	@include verticalScroll();
}

a {
	text-decoration: none;
	outline: none;

	&:active,
	&:focus,
	&:hover,
	&:visited {
		text-decoration: none;
		outline: none;
	}
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	background: initial;

	&:focus,
	&:hover {
		outline: none;
	}
}

img {
	cursor: pointer;
}

#root {
	position: relative;
}

*:focus {
	outline: none;
}

*::selection {
	color: white;
	background-color: #0561d1;
}

*::-moz-selection {
	color: white;
	background-color: #0561d1;
}


.d-none {
	display: none !important;
}

.d-hidden {
	opacity: 0;
}

.d-block {
	display: block !important;
}

.pr-24 {
	padding-right: 24px;
}

.ml-16 {
	margin-left: 16px;
}

.mr-16 {
	margin-right: 16px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-37 {
	margin-top: 37px;
}

.mt-48 {
	margin-top: 48px;
}

.b-44 {
	bottom: 44px;
}

.w-445 {
	@media screen and (min-width: 768px) {
		width: 445px;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.no-scroll {
	height: 100vh;
	overflow-y: hidden;
}

.transparent {
	opacity: 0 !important;
	pointer-events: none;

	* {
		opacity: 0 !important;
		cursor: default !important;
	}
}

.page-container {
	position: relative;
	background: #1d1d1d no-repeat fixed;
	max-width: 100%;
	min-height: 100vh;
	overflow-x: hidden;

	@media screen and (min-width: 768px) {}

	@media screen and (max-width: 767px) {}
}

.mask-parent {
	position: relative !important;
}

.gray-color {
	color: #8B8B8B !important;
}

.top-bar {
	position: relative;
	height: 8px;
	background: #61CEF7;
	transition: width 0.5s;
}

.desktop {
	@media screen and (min-width: 992px) {}

	@media screen and (max-width: 991px) {
		display: none !important;
	}
}

.mobile {
	@media screen and (min-width: 992px) {
		display: none !important;
	}

	@media screen and (max-width: 991px) {}
}

.no-ss-mobile {
	@media screen and (min-width: 375px) {}

	@media screen and (max-width: 374px) {
		display: none !important;
	}
}

.ss-mobile {
	@media screen and (min-width: 375px) {
		display: none !important;
	}

	@media screen and (max-width: 374px) {}
}